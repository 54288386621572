* {
  box-sizing: border-box !important;
}

body {
  line-height: unset !important;
  > iframe {
    z-index: -1000 !important;
    pointer-events: none !important;
  }
}
